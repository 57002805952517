import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as L from "leaflet";

import {
    MapContainer,
    TileLayer,
    useMap,
    Marker,
    Popup
  } from 'react-leaflet';

import './Styles.css'

let Data = () => {
    const { teamUUID, uuid:mapUUID, key:token } = useParams();
    const [status, setStatus] = useState('Please insert some CSV content to analyse it');
    const [csvContent, setCSVContent] = useState('');
    const [objectContent, setObjectContent] = useState([]);
    const [finalContent, setFinalContent] = useState([]);
    const [selectedSeparator, setSelectedSeparator] = useState('none');
    const [packetsData, setPacketsData] = useState('0 / 0');
    const [hasLastSeparator, setHasLastSeparator] = useState(true);
    
    let createItemByAPI = ({ token, itemContent, dataToSend }) => {
        fetch(`https://maps-microservice-overviewbox-com-wldnf2tpna-uc.a.run.app/maps/api/${teamUUID}/${mapUUID}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(itemContent)
        }).then(async data => {
            setStatus('Data sent to the system in ' + (new Date).toLocaleString())
            // setTimeout(() => {
                sendData(finalContent, dataToSend.slice( 50, dataToSend.length))
            // }, 1000);
        }).catch(() => {
            setStatus('Error sending in ' + (new Date).toLocaleString())
        })
    }

    let sendData = (totalData, dataToSend) => {
        setStatus('Trying to send data...');
        setPacketsData(`${Math.ceil((totalData.length - dataToSend.length) / 50)} / ${Math.ceil(totalData.length / 50)}`)
        
        if(dataToSend.length > 0){
            createItemByAPI({
                token: token,
                itemContent: { 
                    multiple: true,
                    data: (dataToSend.slice(0,50))
                },
                dataToSend
            })
        } else {
            alert("Done")
            setStatus('Sent to map');
        }
    }

    let saveLocations = () => {
        setStatus("Saving locations in the map...");
        sendData(finalContent, [...finalContent])
    }

    let CSVtoJSON = (separator=';', csvValue, hasLastSeparator=true) => {
        try {
          let jsonObject = [];
    
          let csvLines = csvValue.split((hasLastSeparator ? (separator + '\n') : '\n'));
          let csvHeader = csvLines[0].split(separator);
          
          delete csvLines[0];
          csvLines.map(lineInfo => {
            let jsonElementObject = {};
            let csvLineData = lineInfo.split(separator)
            csvLineData.map((value, index) => {
              if(csvHeader[index]){
                jsonElementObject[csvHeader[index]] = value;
              }
            })
    
            if(Object.keys(jsonElementObject).length == csvHeader.length){
              jsonObject.push(jsonElementObject)
            }
          })
    
          return jsonObject;
        } catch(e) {
          console.log(e)
          return false;
        }
      }

      
    let JSONtoCSV = (separator=';', jsonObject, hasLastSeparator=true) => {
        try {
            let csv = '';
            let columns = Object.keys(jsonObject[0]);
            csv += columns.join(separator) + (hasLastSeparator ? (separator + '\n') : '\n');
            jsonObject.map((dataValue, index) => {
                columns.map((key) => {
                    csv += (dataValue[key] || '') + separator;
                });

                csv += '\n';
            });

            return csv;
        } catch(e) {
            return false;
        }
    }

    let analyseCSV = () => {
        setStatus("Analysing...")
        let lines = csvContent.split('\n');
        let lastElementIsComma = lines[0][lines[0].length - 1] == ',';
        let lastElementIsCommaAndPoint = lines[0][lines[0].length - 1] == ';';
        let isCommaSeparation = (lines[0].split(',').length > 1) && (lines[0].split(',').length == lines[1].split(',').length);
        let isCommaAndPointSeparation = (lines[0].split(';').length > 1) && (lines[0].split(';').length == lines[1].split(';').length);

        if(lastElementIsComma){
            setStatus("Separator is commas");
            setSelectedSeparator(',');
            setHasLastSeparator(true);
            setObjectContent(CSVtoJSON(',', csvContent, true));
        } else if(lastElementIsCommaAndPoint){
            setStatus("Separator is commas and point");
            setSelectedSeparator(';');
            setHasLastSeparator(true);
            setObjectContent(CSVtoJSON(';', csvContent, true));
        } else if(isCommaSeparation){
            setStatus("Separator is commas without separator");
            setSelectedSeparator(',');
            setHasLastSeparator(false);
            setObjectContent(CSVtoJSON(',', csvContent, false));
        } else if(isCommaAndPointSeparation){
            setStatus("Separator is commas and point without separator");
            setSelectedSeparator(';');
            setHasLastSeparator(false);
            setObjectContent(CSVtoJSON(';', csvContent, false));
        } else {
            setStatus("Error analysing")
        }
    }

    let toOverviewBoxFormat = () => {
        let defaultText = `(Available fields are ${Object.keys(objectContent[0])})`;
        let lat = prompt(`Define latitude field ${defaultText}`) || 'lat'
        let long = prompt(`Define longitude field ${defaultText}`) || 'long'
        let description = prompt(`Define description field ${defaultText}`) || 'description'
        let id = prompt(`Define id field ${defaultText}`) || 'id'

        console.log(objectContent)
        let newObject = [];
        objectContent.map((obj) => {
            newObject.push({
                lat: (obj[lat].length > 0 && !isNaN(obj[lat])) ? obj[lat] : "1",
                long: (obj[long].length > 0 && !isNaN(obj[long])) ? obj[long] : "1",
                description: obj[description]  + ((isNaN(obj[lat]) || isNaN(obj[long])) ? ('[ERROR in LAT or LONG]') : ''),
                id: obj[id],
            })
        })

        setFinalContent(newObject);
    }

    return (
        <div className="content">
            <h3>Share the link bellow with someone to enable searching:</h3>
            <input type="text" className="textInput" value={window.location.href} 
                disabled placeholder="Link" />

            <h1 className="title">Data Tool</h1>
            <h2>{status}</h2>
            <h3>Selected separator is {selectedSeparator}</h3>
            <h3>Last separator verification result is {String(hasLastSeparator)}</h3>
            <h3>Quantity of rows is {Object.keys(objectContent).length}</h3>
            <h3>Tool to upload CSV data or CSV compatible URL and model it for overviewbox format. Lines with errors will be removed.</h3>
            <br />
            
            <textarea type="text" className="textareaInput" value={csvContent} 
                onChange={e => setCSVContent(e.target.value)} placeholder="CSV text"></textarea>
            <input type="button" className="buttonInput"
                onClick={e => analyseCSV()} value={"Analyse" } />
            <br />

            <textarea type="text" className="textareaInput" value={JSONtoCSV(';', objectContent)} disabled 
                placeholder="Current Format"></textarea>
            <input type="button" className="buttonInput"
                onClick={e => toOverviewBoxFormat()} value={"Select Overviewbox Format" } />
            <br />
            

            <textarea type="text" className="textareaInput" value={JSONtoCSV(';', finalContent)} disabled 
                placeholder="Overviewbox Format"></textarea>
            <input type="button" className="buttonInput"
                onClick={e => saveLocations()} value={"Send to map" } />
            <h2>Pending packets {packetsData}</h2>
            <h2>{status}</h2>
            
        </div>
    )
}

export default Data;
