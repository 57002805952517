import logo from './logo.svg';
import './App.css';
import { HashRouter, Routes, Route, useParams } from "react-router-dom";

import {
  MapContainer,
  TileLayer,
  useMap,
  Marker,
  Popup
} from 'react-leaflet';

import { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import Tracking from './tools/Tracking';
import Searching from './tools/Searching';
import Data from './tools/Data';

const firebaseConfig = {
  apiKey: "AIzaSyDZg-B2mXM0Mh1Br6J-fPMMi3EmIZBAJOY",
  authDomain: "overviewbox-a5960.firebaseapp.com",
  projectId: "overviewbox-a5960",
  storageBucket: "overviewbox-a5960.appspot.com",
  messagingSenderId: "179092402606",
  appId: "1:179092402606:web:c5cc09444ef843db08183f"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function MapView() {
  const { teamUUID, uuid } = useParams();
  const [ data, setData ] = useState({ loading: true });
  const [ maxPoints, setMaxPoints ] = useState(20);
  
  useEffect(() => {
    try{
      const unsub = onSnapshot(doc(db, "teams", teamUUID, "maps", uuid), (doc) => {
          let info = (doc.data())

          try{
            setData({ error: info.error, loading: false, title: info.title, points: info.data })
          } catch(e){
            setData({ loading: false, error: true }) 
          }

          console.log("Current data: ", info);
      }, (error) => {
        console.log(error)
        setData({ loading: false, error: true }) 
      });
    } catch(e){
      setData({ loading: false, error: true }) 
    }
  }, []);

  return (
    <div>
      {data.loading ? (
        <header>
          Loading...
        </header>
      ) : (
        data.error ? (
          <header>
            Error loading map
          </header>
        ) : (
          <div>
            <header onClick={() => {setMaxPoints(Number(prompt("Define the quantity of max points")))}}>
              {data.title}
            </header>
            <MapContainer center={[-8.0593548, -34.8880328]} zoom={13} scrollWheelZoom={true}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />

              {(data.points.slice(0, maxPoints)).map(pointInfo => (
                <Marker position={[!isNaN(pointInfo.lat) ? pointInfo.lat : 0, !isNaN(pointInfo.long) ? pointInfo.long : 0]}>
                  <Popup>
                    {pointInfo.description}
                  </Popup>
                </Marker>
              ))}
            </MapContainer>
          </div>
        )
      )}
    </div>
  );
}

const NoPage = () => (
  <div>
    <header>
      Not found
    </header>
  </div>
)

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/tools/data/:teamUUID/:uuid/:key" element={<Data />} />
        <Route path="/tools/searching/:teamUUID/:uuid/:key" element={<Searching />} />
        <Route path="/tools/tracking/:teamUUID/:uuid/:key/:id" element={<Tracking />} />
        <Route path="/:teamUUID/:uuid" element={<MapView />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </HashRouter>
  )
}

export default App;
