import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as L from "leaflet";

import {
    MapContainer,
    TileLayer,
    useMap,
    Marker,
    Popup
  } from 'react-leaflet';

import './Styles.css'

let Searching = () => {
    const { teamUUID, uuid:mapUUID, key:token } = useParams();
    const [status, setStatus] = useState('Any pending searchs');
    const [searchLocation, setSearchLocation] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [savedLocations, setSavedLocations] = useState([]);
    
    const LeafIcon = L.Icon.extend({
        options: {}
    });

    // const greenIcon = new LeafIcon({
    //   iconUrl:
    //     "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|2ecc71&chf=a,s,ee00FFFF"
    // });
    
    let createItemByAPI = ({ token, itemContent }) => {
        fetch(`https://maps-microservice-overviewbox-com-wldnf2tpna-uc.a.run.app/maps/api/${teamUUID}/${mapUUID}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(itemContent)
        }).then(async data => {
            setStatus('Data sent to the system in ' + (new Date).toLocaleString())
            alert("Locations saved")
        }).catch(() => {
            setStatus('Error sending in ' + (new Date).toLocaleString())
            alert("Error saving locations")
        })
    }

    let sendData = () => {
        setStatus('Trying to send data...');
        
        createItemByAPI({
            token: token,
            itemContent: { 
                multiple: true,
                data: Object.values(savedLocations)
            }
        })
    }

    let saveLocations = () => {
        alert("Saving locations in the map...");
        sendData()
    }

    let searchByText = (text) => {
        setStatus('Searching using Nominatim by Open Street Map...')
        fetch(`https://nominatim.openstreetmap.org/search.php?q=${window.encodeURIComponent(text)}&format=jsonv2`, {
            method: 'GET'
        }).then(async (data) => {
            setSearchResults(await data.json())
            setStatus('Search done')
        }).catch(() => {
            setStatus('Error when searching.')
        })
    }

    return (
        <div className="content">
            <div className="leftContent">
                <h3>Share the link bellow with someone to enable searching:</h3>
                <input type="text" className="textInput" value={window.location.href} 
                    disabled placeholder="Link" />

                <h1 className="title">Save</h1>
                <input type="button" className="buttonInput"
                        value="Save Locations In Map" onClick={() => saveLocations()} />
                
                <h1 className="title">Searching</h1>
                <h2>{status}</h2>
                <h3>This page is connected to a specfic map and with write access included, to make easier the creation of the map. Please be careful with who can have this link.</h3>

                <form onSubmit={e => {e.preventDefault();searchByText(searchLocation);return false}}>
                    <input type="text" className="textInput" value={searchLocation} 
                        onChange={e => setSearchLocation(e.target.value)} placeholder="Search Location" />
                    <input type="submit" className="buttonInput"
                        value="Search" />
                </form>
                    
                {(searchResults).map(({
                    place_id,
                    display_name,
                    lat,
                    lon
                }) => (
                    <div className="option">
                        <input 
                            type="button" 
                            className="buttonInput" 
                            onClick={() => { setSavedLocations({...savedLocations, [place_id]: {
                                description: display_name,
                                lat: lat,
                                long: lon,
                                id: place_id
                            }})}} 
                            value="Add to map" /><br />
                            
                        <div className="displayName">{display_name}</div>
                        <div className="coords">Latitude: {lat}; Longitude: {lon}</div>
                    </div>
                ))}

                <h1 className="title">Saved Locations</h1>
                {Object.values(savedLocations).length == 0 && (
                    <h2>Please add some locations</h2>
                )}

                {Object.values(savedLocations).map(pointInfo => (
                    <div className="option">
                        <input 
                            type="button" 
                            className="buttonInput" 
                            onClick={() => { 
                                let newSavedLocations = {...savedLocations};
                                delete newSavedLocations[pointInfo.id];
                                setSavedLocations({...newSavedLocations})
                            }} 
                            value="Remove of map" /><br />
                        <input 
                            type="button" 
                            className="buttonInput" 
                            onClick={() => { 
                                pointInfo.lastId = pointInfo.id;
                                delete savedLocations[pointInfo.id];
                                pointInfo.id = pointInfo.lat  + '_' + pointInfo.long;
                                setSavedLocations({...savedLocations, [pointInfo.id]: {
                                    ...pointInfo,
                                    draggable: !pointInfo.draggable,
                                }})
                            }} 
                            style={{ background: pointInfo.draggable ? 'rgb(20, 100, 20)' : 'black' }}
                            value={pointInfo.draggable ? `Turn fixed [${pointInfo.lat},${pointInfo.long}]` : "Turn draggable"} /><br />
                        <input 
                            type="button" 
                            className="buttonInput" 
                            onClick={() => { 
                                setSavedLocations({
                                    ...savedLocations,
                                    [pointInfo.id]: {
                                        ...pointInfo,
                                        description: prompt("Please define the new description")
                                    }
                                })
                            }} 
                            value="Change description" />
                        <div className="displayName">{pointInfo.description}</div>
                        <div className="coords">Latitude: {pointInfo.lat}; Longitude: {pointInfo.long}</div>
                    </div>
                ))}
            </div>
            <div className="rightContent">
                <MapContainer center={[-8.0593548, -34.8880328]} zoom={13} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    {Object.values(savedLocations).map(pointInfo => (
                        <Marker draggable={pointInfo.draggable || false} 
                            eventHandlers={{
                                dragend(e) {
                                    let latlong = (e.target.getLatLng())
                                    
                                    setSavedLocations({
                                        ...savedLocations,
                                        [pointInfo.id]: {
                                            ...pointInfo,
                                            lat: latlong.lat,
                                            long: latlong.lng
                                        }
                                    })
                                }
                            }} position={[pointInfo.lat, pointInfo.long]}>
                            <Popup>
                                {pointInfo.description}
                            </Popup>
                        </Marker>
                    ))}
                </MapContainer>
            </div>
        </div>
    )
}

export default Searching;
