import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import './Styles.css'

let Tracking = () => {
    const { teamUUID, uuid:mapUUID, key:token, id } = useParams();
    const [status, setStatus] = useState('Not syncronized');
    const [run, setRun] = useState(false);
    const [sendingData, setSendingData] = useState(false);
    const [itemData, setItemData] = useState({
        latitude: '',
        longitude: '',
        id,
        token,
        teamUUID,
        mapUUID,
        locationLastUpdateIn: 'none',
        description: 'UPDATING TARGET / STARTED AT ' + (new Date).toLocaleString()
    })

    let createItemByAPI = ({ token, itemContent }) => {
        fetch(`https://maps-microservice-overviewbox-com-wldnf2tpna-uc.a.run.app/maps/api/${itemData.teamUUID}/${itemData.mapUUID}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(itemContent)
        }).then(async data => {
        setStatus('Data sent to the system in ' + (new Date).toLocaleString())
        }).catch(() => {
        setStatus('Error sending in ' + (new Date).toLocaleString())
        })
    }

    let sendData = (run) => {
        if(run) {
            setStatus('Trying to send data...');
            
            createItemByAPI({
                token: itemData.token,
                itemContent: {
                    lat: itemData.latitude,
                    long: itemData.longitude,
                    id: itemData.id,
                    description: `[${itemData.id}] ${itemData.description} (UPDATED IN ${(new Date).toLocaleString()})`
                }
            })
        } else {
            setStatus('Not syncronized');
        }
    }

    navigator.geolocation.watchPosition((position) => {
        setItemData({
            ...itemData,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            locationLastUpdateIn: (new Date).toLocaleString()
        })
    });

    useEffect(() => {
        const interval = setInterval(() => {
            sendData(run)
        }, 3000);

        return  () => {
        clearInterval(interval)
        } 
    }, [run, itemData]);

    return (
        <div className="content">
            <h1 className="title">Tracking</h1>
            <h2>{status}</h2>
            <h3>Location updated in {itemData.locationLastUpdateIn}</h3>
            <input type="hidden" className="textInput" value={itemData.teamUUID} 
                onChange={e => setItemData({ ...itemData, teamUUID: e.target.value })} placeholder="Team UUID" />
            <input type="hidden" className="textInput" value={itemData.mapUUID} 
                onChange={e => setItemData({ ...itemData, mapUUID: e.target.value })} placeholder="Map UUID" />
            <input type="hidden" className="textInput" value={itemData.token} 
                onChange={e => setItemData({ ...itemData, token: e.target.value })} placeholder="API Token" />
            <input type="text" className="textInput" value={itemData.id} 
                onChange={e => setItemData({ ...itemData, id: e.target.value })} placeholder="Point ID" />
            <input type="text" className="textInput" value={itemData.description} 
                onChange={e => setItemData({ ...itemData, description: e.target.value })} placeholder="Description" />
            <input type="text" className="textInput" value={itemData.latitude} 
                onChange={e => setItemData({ ...itemData, latitude: e.target.value })} disabled placeholder="Latitude" />
            <input type="text" className="textInput" value={itemData.longitude} 
                onChange={e => setItemData({ ...itemData, longitude: e.target.value })} disabled placeholder="Longitude" />
            <input type="button" className="buttonInput"
                onClick={e => setRun(!run)} value={run ? "Disconnect" : "Connect" } />
                
            <h3>Share the link bellow with someone to enable tracking:</h3>
            <input type="text" className="textInput" value={window.location.href} 
                 disabled placeholder="Link" />
        </div>
    )
}

export default Tracking;
